<template>
  <div class="charterDispatchPopup">
    <el-dialog
      v-if="dialogVisible"
      :close-on-click-modal="type == '详情'"
      :show-close="false"
      :visible.sync="dialogVisible"
      :width="
        ['派车', '改派', '退款'].includes(type)
          ? '500px'
          : type != '详情'
          ? '800px'
          : '50%'
      "
      :title="type"
    >
      <el-form
        v-if="type != '详情'"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="96px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <template v-if="type == '报价' || type == '修改报价'">
          <el-form-item label="预估费用：" prop="totalComboPrices">
            <el-input
              v-model="ruleForm.totalComboPrices"
              placeholder="请输入"
              @input="
                ruleForm.totalComboPrices = ruleForm.totalComboPrices.replace(
                  /^(0+)|[^\d]+/g,
                  ''
                )
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="费用说明：" prop="costRemark">
            <el-input
              v-model="ruleForm.costRemark"
              :autosize="{ minRows: 2, maxRows: 6 }"
              show-word-limit
              type="textarea"
              resize="none"
              maxlength="1000"
              placeholder="请填写需用户知晓的相关费用说明"
            ></el-input>
          </el-form-item>
          <el-form-item label="行程说明：" prop="travelDescription">
            <el-input
              v-model="ruleForm.travelDescription"
              :autosize="{ minRows: 2, maxRows: 6 }"
              show-word-limit
              type="textarea"
              resize="none"
              maxlength="1000"
              placeholder="请填写与乘客沟通的详细行程信息及其它事项"
            ></el-input>
          </el-form-item>
          <el-form-item label="车商名称：" required>
            <span>{{ ruleForm.carServiceProviderName }}</span>
          </el-form-item>
          <el-form-item label="车型分类：" required>
            <div>
              <div class="car-info">
                <div class="car-name">{{ ruleForm.vehicleTypeName }}</div>
                <div class="car-num">
                  {{
                    `${ruleForm.passengerNum}人 · ${ruleForm.luggageNum}行李`
                  }}
                </div>
              </div>
              <div class="explain lineHeight26">
                {{ ruleForm.modelTypeDescription }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="费用包含：" required>
            <div class="lineHeight26 has">{{ ruleForm.costInfo || "-" }}</div>
          </el-form-item>
          <el-form-item label="费用不含：" required>
            <el-table
              v-if="ruleForm.charteredCostItems"
              :data="ruleForm.charteredCostItems"
              border
              style="width: 100%"
              height="auto"
            >
              <el-table-column
                prop="costName"
                label="费用名称"
                align="center"
                width="120px"
              >
              </el-table-column>
              <el-table-column
                prop="modelTypeDescription"
                label="费用描述"
                align="center"
                width="240"
              >
                <template slot-scope="scope">
                  <div class="lineHeight26 explain">
                    {{ scope.row.modelTypeDescription }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="cost" label="收费标准" align="center">
              </el-table-column>
              <el-table-column prop="unit" label="单位" align="center">
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
        <template v-if="type == '派车' || type == '改派'">
          <el-form-item label="车辆：" prop="vehicleId">
            <el-select
              v-model="ruleForm.vehicleId"
              filterable
              remote
              reserve-keyword
              clearable
              placeholder="请选择"
              @change="changeVehicleId"
            >
              <el-option
                v-for="single in cphList"
                :key="single.id"
                :label="single.licensePlateNumber"
                :value="single.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="驾驶员：" prop="driverId1">
            <el-select
              v-model="ruleForm.driverId1"
              filterable
              remote
              reserve-keyword
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="single in driverList"
                :key="single.id"
                :label="single.userName"
                :value="single.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="doubleDriving" label="" prop="driverId2">
            <el-select
              v-model="ruleForm.driverId2"
              filterable
              remote
              reserve-keyword
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="single in driverList"
                :key="single.id"
                :label="single.userName"
                :value="single.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="doubleDriving">双驾</el-checkbox>
          </el-form-item>
        </template>
        <template v-if="type == '退款'">
          <el-form-item label="支付金额：">
            <span
              >{{
                ruleForm.orderStatusCode == 50
                  ? ruleForm.totalComboPrices
                  : ruleForm.totalPrices
              }}元</span
            >
          </el-form-item>
          <el-form-item label="退款金额：" prop="refundProportion">
            <el-input
              v-model="ruleForm.refundProportion"
              placeholder="请输入"
              @input="
                ruleForm.refundProportion = ruleForm.refundProportion.replace(
                  /^(0+)|[^\d]+/g,
                  ''
                )
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              v-model="ruleForm.reason"
              :autosize="{ minRows: 4, maxRows: 8 }"
              show-word-limit
              type="textarea"
              resize="none"
              maxlength="1000"
              placeholder="请填写退款原因"
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
      <template v-else>
        <div>
          <h3>订单信息</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <span>订单编号：</span>
              <span>{{ ruleForm.id }}</span>
            </el-col>
            <el-col :span="12">
              <span>下单时间：</span>
              <span>{{ ruleForm.createTime }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <span>订单状态：</span>
              <span
                :style="{
                  color:
                    ruleForm.orderStatus == 20
                      ? '#336ffe'
                      : ruleForm.orderStatus == 70
                      ? '#000000'
                      : ruleForm.orderStatus == 90 ||
                        ruleForm.orderStatus == 100 ||
                        ruleForm.orderStatus == 110
                      ? '#d40000'
                      : '#67c23a',
                }"
                >{{ ruleForm.orderStatus | orderStatusFilter }}</span
              >
              <span v-if="ruleForm.refundAt">({{ ruleForm.refundAt }})</span>
            </el-col>
            <el-col :span="12">
              <span>订单总额：</span>
              <span>{{ ruleForm.totalPrices || 0 }}元</span>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" class="charteredCostItems-box">
              <span>费用说明：</span>
              <div class="text">{{ ruleForm.costRemark || "-" }}</div>
            </el-col>
            <el-col
              :span="12"
              v-if="ruleForm.extraCostRemark"
              class="charteredCostItems-box"
            >
              <span>额外费用说明：</span>
              <div class="text">{{ ruleForm.extraCostRemark }}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="7" class="price-box">
              <div>
                <span>平台报价：</span>
                <span>{{ ruleForm.totalComboPrices || 0 }}元</span>
              </div>
              <div>
                <span>支付时间：</span>
                <span>{{ ruleForm.wxTransactionTime || "-" }}</span>
              </div>
              <!-- <div>
                <span style="color: #919191">{{ ruleForm.costRemark }}</span>
              </div> -->
            </el-col>
            <el-col :span="7" class="price-box">
              <div>
                <span>额外费用：</span>
                <span>{{ ruleForm.extraCost || 0 }}元</span>
              </div>
              <div>
                <span>支付时间：</span>
                <span>{{ ruleForm.payExtraCostAt || "-" }}</span>
              </div>
              <!-- <div>
                <span style="color: #919191">{{
                  ruleForm.extraCostRemark || "-"
                }}</span>
              </div> -->
            </el-col>
            <el-col :span="7" class="price-box">
              <div>
                <span>退款金额：</span>
                <span>{{ ruleForm.refundOrderPrices || 0 }}元</span>
              </div>
              <div>
                <span>退款手续费：</span>
                <span>{{ ruleForm.refundOrderServicePrices || 0 }}元</span>
              </div>
              <!-- <div>
                <span>退款时间：</span>
                <span>{{ ruleForm.refundAt || '-' }}</span>
              </div> -->
            </el-col>
          </el-row>
          <h3>行程信息</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <span>出发地：</span>
              <span>{{ ruleForm.startPoint.name }}</span>
            </el-col>
            <el-col :span="12">
              <span>目的地：</span>
              <span v-if="ruleForm.endStation">{{
                ruleForm.endStation.name
              }}</span>
              <span v-else>-</span>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <span>用车时间：</span>
              <span>{{ ruleForm.startAt }}</span>
            </el-col>
            <el-col :span="12" class="charteredCostItems-box">
              <span>用车需求：</span>
              <div class="text">{{ ruleForm.vehicleDemand || "-" }}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" class="charteredCostItems-box">
              <span>行程说明：</span>
              <div class="text">{{ ruleForm.travelDescription }}</div>
            </el-col>
          </el-row>
          <h3>乘客信息</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <span>姓名：</span>
              <span>{{ ruleForm.passengerName }}</span>
            </el-col>
            <el-col :span="12">
              <span>联系方式：</span>
              <span>{{ ruleForm.passengerPhone }}</span>
            </el-col>
          </el-row>
          <h3>车商信息</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <span>车型分类：</span>
              <span>{{ ruleForm.vehicleTypeName }}</span>
            </el-col>
            <el-col :span="12">
              <span>车商名称：</span>
              <span>{{ ruleForm.carServiceProviderName }}</span>
            </el-col>
          </el-row>
          <h3>派车信息</h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <span>车牌号：</span>
              <span>{{ ruleForm.vehiclePlateNumber || "-" }}</span>
            </el-col>
            <el-col :span="12" class="charteredCostItems-box">
              <span>驾驶员：</span>
              <div
                v-if="ruleForm.driverName1 || ruleForm.driverName2"
                class="text"
              >
                <span v-if="ruleForm.driverName1">{{
                  `${ruleForm.driverName1}（${ruleForm.driverPhone1}）`
                }}</span>
                <span v-if="ruleForm.driverName2">{{
                  `；${ruleForm.driverName2}（${ruleForm.driverPhone2}）`
                }}</span>
              </div>
              <span v-else>-</span>
            </el-col>
          </el-row>
          <h3>计费规则</h3>
          <el-row :gutter="20">
            <el-col :span="22" class="charteredCostItems-box">
              <span>费用包含：</span>
              <div class="text">{{ ruleForm.costInfo || "-" }}</div>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 32px">
            <el-col :span="22" class="charteredCostItems-box">
              <span>费用不含：</span>
              <div class="text">
                <el-table
                  v-if="ruleForm.charteredCostManagementDetails"
                  :data="ruleForm.charteredCostManagementDetails"
                  border
                  style="width: 95%"
                  height="auto"
                >
                  <el-table-column
                    prop="costName"
                    label="费用名称"
                    align="center"
                    width="140px"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="modelTypeDescription"
                    label="费用描述"
                    align="center"
                    width="300"
                  >
                    <template slot-scope="scope">
                      <div class="lineHeight26 explain">
                        {{ scope.row.modelTypeDescription }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="cost" label="收费标准" align="center">
                  </el-table-column>
                  <el-table-column prop="unit" label="单位" align="center">
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">{{
          type != "详情" ? "取 消" : "关 闭"
        }}</el-button>
        <el-button
          v-if="type != '详情'"
          size="small"
          type="primary"
          @click="confirm"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  quotationAPI,
  getVehicleList,
  getDriverList,
  putWaitingSchedulingOrderAPI,
  charterRefundOrderAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      type: null,
      cphList: [],
      driverList: [],
      doubleDriving: false,
      dialogVisible: false,
      ruleForm: {
        totalComboPrices: null,
        seatNum: null,
        luggageNum: null,
        modelTypeDescription: null,
        vehicleId: null,
        driverId1: null,
        driverId2: null,
        totalPrices: 0,
        refundProportion: null,
        reason: null,
        id: null,
        createTime: null,
        orderStatus: null,
        orderStatusCode: null,
        totalComboPrices: null,
        costPaymentTime: null,
        costRemark: null,
        additionalCosts: null,
        additionalCostsPaymentTime: null,
        additionalCostsDescription: null,
        passengerName: null,
        passengerPhone: null,
        passengerNum: null,
        startStation: null,
        endStation: null,
        startAt: null,
        vehicleDemand: null,
        travelDescription: null,
        vehicleTypeName: null,
        vehicleTypeDictCode: null,
        carServiceProviderName: null,
        carServiceProviderId: null,
        cph: null,
        driverName: null,
        driverPhone: null,
        refundOrderPrices: null,
        refundServiceAmount: null,
        refundOrderTime: null,
        costInfo: null,
        charteredCostItems: [],
        charteredCostManagementDetails: [],
      },
      rules: {
        totalComboPrices: [
          { required: true, message: "请输入预估费用", trigger: "blur" },
        ],
        costRemark: [
          { required: true, message: "请输入费用说明", trigger: "blur" },
        ],
        travelDescription: [
          { required: true, message: "请输入行程说明", trigger: "blur" },
        ],
        vehicleId: [{ required: true, message: "请选择", trigger: "change" }],
        driverId1: [{ required: true, message: "请选择", trigger: "change" }],
        driverId2: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        refundProportion: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback("请输入退款金额");
              } else if (Number(value) > this.ruleForm.totalPrices) {
                callback("不能大于支付金额");
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  filters: {
    orderStatusFilter(v) {
      let text = "";
      switch (v) {
        case 5:
          text = "待报价";
          break;
        case 10:
          text = "待支付";
          break;
        case 20:
          text = "待派车";
          break;
        case 70:
          text = "已完成";
          break;
        case 90:
          text = "已退款";
          break;
        case 100:
          text = "已退款";
          break;
        case 110:
          text = "已取消";
          break;
        default:
          text = "服务中";
          break;
      }
      return text;
    },
  },
  computed: {},
  watch: {
    dialogVisible(v) {
      if (!v) {
        this.$refs.ruleForm.resetFields();
        this.doubleDriving = false;
        // this.ruleForm = this.$options.data().ruleForm;
      }
    },
    doubleDriving(v) {
      if (!v) {
        this.ruleForm.driverId2 = null;
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    changeVehicleId(v) {
      this.ruleForm.driverId1 = null;
      this.ruleForm.driverId2 = null;
    },
    queryCph(scopeRow) {
      getVehicleList({
        operationMode: [4],
        currentPage: 1,
        pageSize: 999,
        companyIds: [scopeRow.carServiceProvider.companyId],
        // companyId: scopeRow.carServiceProvider.companyId,
        // id: scopeRow.charteredVehicleModelType.id,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.cphList = res.data.list;
        }
      });
    },
    queryDriver(scopeRow) {
      getDriverList({
        driveType: 1,
        currentPage: 1,
        pageSize: 999,
        companyIds: [scopeRow.carServiceProvider.companyId],
        // companyId: scopeRow.carServiceProvider.companyId,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.driverList = res.data.list;
        }
      });
    },
    open(scopeRow, type) {
      this.ruleForm = Object.assign(this.ruleForm, scopeRow);
      this.type = type;
      this.doubleDriving = !!this.ruleForm.driverId2;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.type === "报价" || this.type === "修改报价") {
            quotationAPI({
              costRemark: this.ruleForm.costRemark,
              travelDescription: this.ruleForm.travelDescription,
              orderId: this.ruleForm.id,
              totalComboPrices: this.ruleForm.totalComboPrices,
            }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("操作成功！");
                this.$parent.getDataList();
                this.$parent.getCharteredSchedulingOrderCountVOFun();
                this.close();
              }
            });
          } else if (this.type === "派车" || this.type === "改派") {
            let params = {};
            this.cphList.map((res) => {
              if (res.id == this.ruleForm.vehicleId) {
                params.vehicleCompanyName = res.companyName;
                params.vehicleCompanyId = res.companyId;
                params.vehiclePlateNumber = res.licensePlateNumber;
                params.vehicleBrand = res.brand;
                params.vehicleSeatNumber = res.seatNumber;
                params.vehiclePlateColor = res.licensePlateColorStr;
                params.vehicleColor = res.vehicleColorStr;
                params.vehicleId = res.id;
              }
            });
            this.driverList.map((res) => {
              if (res.id == this.ruleForm.driverId1) {
                params.driverId1 = res.id;
                params.driverName1 = res.userName;
                params.driverPhone1 = res.phone;
                params.driverCompanyId = res.companyId;
                params.driverCompanyName = res.companyName;
              }
              if (res.id == this.ruleForm.driverId2) {
                params.driverId2 = res.id;
                params.driverName2 = res.userName;
                params.driverPhone2 = res.phone;
                params.driverCompanyId = res.companyId;
                params.driverCompanyName = res.companyName;
              }
            });
            params.charteredOrderId = this.ruleForm.id;
            putWaitingSchedulingOrderAPI(params).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("操作成功！");
                this.$parent.getDataList();
                this.$parent.getCharteredSchedulingOrderCountVOFun();
                this.close();
              }
            });
          } else if (this.type === "退款") {
            charterRefundOrderAPI({
              orderId: this.ruleForm.id,
              refundProportion: Number(this.ruleForm.refundProportion),
              reason: this.ruleForm.reason,
              isRefundRatio: false,
            }).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("操作成功！");
                this.$parent.getDataList();
                this.$parent.getCharteredSchedulingOrderCountVOFun();
                this.close();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  padding: 0 21px;
  line-height: 46px;
  height: 46px;
  font-weight: bold;
  background-color: #f7f8f9;
  border-left: #336ffe solid 2px;
}
::v-deep .el-table:not(.no-blank) {
  .el-table__empty-block {
    .el-table__empty-text {
      height: 60px;
    }
  }
}
::v-deep .dialog-form {
  padding: 1.2vh 2vw;
}
.has {
  padding: 5px 0 10px;
}
.lineHeight26 {
  line-height: 26px;
}
.car-info {
  display: flex;
  align-items: flex-end;
  .car-num {
    font-size: 12px;
    color: #999;
    margin-left: 6px;
  }
}
.explain {
  color: #999;
}
.charterDispatchPopup {
  ::v-deep .el-dialog {
    max-height: 82vh;
    margin-top: 8vh !important;
    .el-dialog__body {
      max-height: 600px;
      overflow: auto;
    }
  }
  ::v-deep .el-form-item__label {
    line-height: 40px !important;
  }
  ::v-deep .el-input__inner,
  ::v-deep .el-textarea__inner {
    width: inherit !important;
  }
  ::v-deep .el-textarea__inner {
    padding-bottom: 18px;
  }
  ::v-deep .el-input__count {
    bottom: -5px !important;
    right: 20px !important;
    background: unset !important;
  }
  ::v-deep .el-input__suffix {
    right: 5px !important;
  }
  .el-row {
    padding: 0 20px;
    margin-bottom: 22px;
    .el-col {
      color: #000;
      align-items: flex-start;
      .text {
        line-height: 28px;
        flex: 1;
        margin-top: -6px;
      }
      & > span:nth-child(1) {
        display: inline-block;
        width: 108px;
        color: #828485;
      }
    }
    .charteredCostItems-box {
      display: flex;
      .costName-box {
        display: flex;
        justify-content: space-between;
      }
    }
    .price-box {
      background: rgba(51, 111, 254, 0.1);
      border-radius: 10px;
      padding: 16px 16px 10px;
      margin-right: 20px;
      // min-height: 100px;
      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        & > span:nth-child(1) {
          color: #828485;
        }
      }
    }
  }
}
</style>
