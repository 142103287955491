var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "charterDispatchPopup" },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": _vm.type == "详情",
                "show-close": false,
                visible: _vm.dialogVisible,
                width: ["派车", "改派", "退款"].includes(_vm.type)
                  ? "500px"
                  : _vm.type != "详情"
                  ? "800px"
                  : "50%",
                title: _vm.type,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _vm.type != "详情"
                ? _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-form dialog-form",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "96px",
                        "label-position": "left",
                      },
                    },
                    [
                      _vm.type == "报价" || _vm.type == "修改报价"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "预估费用：",
                                  prop: "totalComboPrices",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  on: {
                                    input: function ($event) {
                                      _vm.ruleForm.totalComboPrices =
                                        _vm.ruleForm.totalComboPrices.replace(
                                          /^(0+)|[^\d]+/g,
                                          ""
                                        )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.totalComboPrices,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "totalComboPrices",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.totalComboPrices",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "费用说明：",
                                  prop: "costRemark",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    autosize: { minRows: 2, maxRows: 6 },
                                    "show-word-limit": "",
                                    type: "textarea",
                                    resize: "none",
                                    maxlength: "1000",
                                    placeholder:
                                      "请填写需用户知晓的相关费用说明",
                                  },
                                  model: {
                                    value: _vm.ruleForm.costRemark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "costRemark", $$v)
                                    },
                                    expression: "ruleForm.costRemark",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "行程说明：",
                                  prop: "travelDescription",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    autosize: { minRows: 2, maxRows: 6 },
                                    "show-word-limit": "",
                                    type: "textarea",
                                    resize: "none",
                                    maxlength: "1000",
                                    placeholder:
                                      "请填写与乘客沟通的详细行程信息及其它事项",
                                  },
                                  model: {
                                    value: _vm.ruleForm.travelDescription,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "travelDescription",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.travelDescription",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "车商名称：", required: "" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.ruleForm.carServiceProviderName)
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "车型分类：", required: "" } },
                              [
                                _c("div", [
                                  _c("div", { staticClass: "car-info" }, [
                                    _c("div", { staticClass: "car-name" }, [
                                      _vm._v(
                                        _vm._s(_vm.ruleForm.vehicleTypeName)
                                      ),
                                    ]),
                                    _c("div", { staticClass: "car-num" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${_vm.ruleForm.passengerNum}人 · ${_vm.ruleForm.luggageNum}行李`
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "explain lineHeight26" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ruleForm.modelTypeDescription
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "费用包含：", required: "" } },
                              [
                                _c("div", { staticClass: "lineHeight26 has" }, [
                                  _vm._v(_vm._s(_vm.ruleForm.costInfo || "-")),
                                ]),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "费用不含：", required: "" } },
                              [
                                _vm.ruleForm.charteredCostItems
                                  ? _c(
                                      "el-table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          data: _vm.ruleForm.charteredCostItems,
                                          border: "",
                                          height: "auto",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "costName",
                                            label: "费用名称",
                                            align: "center",
                                            width: "120px",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "modelTypeDescription",
                                            label: "费用描述",
                                            align: "center",
                                            width: "240",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "lineHeight26 explain",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              scope.row
                                                                .modelTypeDescription
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2933945556
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "cost",
                                            label: "收费标准",
                                            align: "center",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "unit",
                                            label: "单位",
                                            align: "center",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.type == "派车" || _vm.type == "改派"
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车辆：", prop: "vehicleId" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    on: { change: _vm.changeVehicleId },
                                    model: {
                                      value: _vm.ruleForm.vehicleId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "vehicleId", $$v)
                                      },
                                      expression: "ruleForm.vehicleId",
                                    },
                                  },
                                  _vm._l(_vm.cphList, function (single) {
                                    return _c("el-option", {
                                      key: single.id,
                                      attrs: {
                                        label: single.licensePlateNumber,
                                        value: single.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "驾驶员：", prop: "driverId1" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.ruleForm.driverId1,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "driverId1", $$v)
                                      },
                                      expression: "ruleForm.driverId1",
                                    },
                                  },
                                  _vm._l(_vm.driverList, function (single) {
                                    return _c("el-option", {
                                      key: single.id,
                                      attrs: {
                                        label: single.userName,
                                        value: single.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.doubleDriving
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "", prop: "driverId2" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          remote: "",
                                          "reserve-keyword": "",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.ruleForm.driverId2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "driverId2",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.driverId2",
                                        },
                                      },
                                      _vm._l(_vm.driverList, function (single) {
                                        return _c("el-option", {
                                          key: single.id,
                                          attrs: {
                                            label: single.userName,
                                            value: single.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.doubleDriving,
                                      callback: function ($$v) {
                                        _vm.doubleDriving = $$v
                                      },
                                      expression: "doubleDriving",
                                    },
                                  },
                                  [_vm._v("双驾")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.type == "退款"
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "支付金额：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ruleForm.orderStatusCode == 50
                                        ? _vm.ruleForm.totalComboPrices
                                        : _vm.ruleForm.totalPrices
                                    ) + "元"
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "退款金额：",
                                  prop: "refundProportion",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  on: {
                                    input: function ($event) {
                                      _vm.ruleForm.refundProportion =
                                        _vm.ruleForm.refundProportion.replace(
                                          /^(0+)|[^\d]+/g,
                                          ""
                                        )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.refundProportion,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "refundProportion",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.refundProportion",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    autosize: { minRows: 4, maxRows: 8 },
                                    "show-word-limit": "",
                                    type: "textarea",
                                    resize: "none",
                                    maxlength: "1000",
                                    placeholder: "请填写退款原因",
                                  },
                                  model: {
                                    value: _vm.ruleForm.reason,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "reason", $$v)
                                    },
                                    expression: "ruleForm.reason",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : [
                    _c(
                      "div",
                      [
                        _c("h3", [_vm._v("订单信息")]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("订单编号：")]),
                              _c("span", [_vm._v(_vm._s(_vm.ruleForm.id))]),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("下单时间：")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.createTime)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("订单状态：")]),
                              _c(
                                "span",
                                {
                                  style: {
                                    color:
                                      _vm.ruleForm.orderStatus == 20
                                        ? "#336ffe"
                                        : _vm.ruleForm.orderStatus == 70
                                        ? "#000000"
                                        : _vm.ruleForm.orderStatus == 90 ||
                                          _vm.ruleForm.orderStatus == 100 ||
                                          _vm.ruleForm.orderStatus == 110
                                        ? "#d40000"
                                        : "#67c23a",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderStatusFilter")(
                                        _vm.ruleForm.orderStatus
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm.ruleForm.refundAt
                                ? _c("span", [
                                    _vm._v(
                                      "(" + _vm._s(_vm.ruleForm.refundAt) + ")"
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("订单总额：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.ruleForm.totalPrices || 0) + "元"
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "charteredCostItems-box",
                                attrs: { span: 12 },
                              },
                              [
                                _c("span", [_vm._v("费用说明：")]),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v(
                                    _vm._s(_vm.ruleForm.costRemark || "-")
                                  ),
                                ]),
                              ]
                            ),
                            _vm.ruleForm.extraCostRemark
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "charteredCostItems-box",
                                    attrs: { span: 12 },
                                  },
                                  [
                                    _c("span", [_vm._v("额外费用说明：")]),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        _vm._s(_vm.ruleForm.extraCostRemark)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { staticClass: "price-box", attrs: { span: 7 } },
                              [
                                _c("div", [
                                  _c("span", [_vm._v("平台报价：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ruleForm.totalComboPrices || 0
                                      ) + "元"
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("span", [_vm._v("支付时间：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ruleForm.wxTransactionTime || "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "price-box", attrs: { span: 7 } },
                              [
                                _c("div", [
                                  _c("span", [_vm._v("额外费用：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.ruleForm.extraCost || 0) + "元"
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("span", [_vm._v("支付时间：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.ruleForm.payExtraCostAt || "-")
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "price-box", attrs: { span: 7 } },
                              [
                                _c("div", [
                                  _c("span", [_vm._v("退款金额：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ruleForm.refundOrderPrices || 0
                                      ) + "元"
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("span", [_vm._v("退款手续费：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ruleForm.refundOrderServicePrices ||
                                          0
                                      ) + "元"
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("h3", [_vm._v("行程信息")]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("出发地：")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.startPoint.name)),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("目的地：")]),
                              _vm.ruleForm.endStation
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.ruleForm.endStation.name)
                                    ),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("用车时间：")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.startAt)),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticClass: "charteredCostItems-box",
                                attrs: { span: 12 },
                              },
                              [
                                _c("span", [_vm._v("用车需求：")]),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v(
                                    _vm._s(_vm.ruleForm.vehicleDemand || "-")
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "charteredCostItems-box",
                                attrs: { span: 12 },
                              },
                              [
                                _c("span", [_vm._v("行程说明：")]),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v(
                                    _vm._s(_vm.ruleForm.travelDescription)
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("h3", [_vm._v("乘客信息")]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("姓名：")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.passengerName)),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("联系方式：")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.passengerPhone)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c("h3", [_vm._v("车商信息")]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("车型分类：")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.vehicleTypeName)),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("车商名称：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.ruleForm.carServiceProviderName)
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c("h3", [_vm._v("派车信息")]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("span", [_vm._v("车牌号：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.ruleForm.vehiclePlateNumber || "-")
                                ),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticClass: "charteredCostItems-box",
                                attrs: { span: 12 },
                              },
                              [
                                _c("span", [_vm._v("驾驶员：")]),
                                _vm.ruleForm.driverName1 ||
                                _vm.ruleForm.driverName2
                                  ? _c("div", { staticClass: "text" }, [
                                      _vm.ruleForm.driverName1
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                `${_vm.ruleForm.driverName1}（${_vm.ruleForm.driverPhone1}）`
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.ruleForm.driverName2
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                `；${_vm.ruleForm.driverName2}（${_vm.ruleForm.driverPhone2}）`
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("h3", [_vm._v("计费规则")]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "charteredCostItems-box",
                                attrs: { span: 22 },
                              },
                              [
                                _c("span", [_vm._v("费用包含：")]),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v(_vm._s(_vm.ruleForm.costInfo || "-")),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          {
                            staticStyle: { "margin-top": "32px" },
                            attrs: { gutter: 20 },
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "charteredCostItems-box",
                                attrs: { span: 22 },
                              },
                              [
                                _c("span", [_vm._v("费用不含：")]),
                                _c(
                                  "div",
                                  { staticClass: "text" },
                                  [
                                    _vm.ruleForm.charteredCostManagementDetails
                                      ? _c(
                                          "el-table",
                                          {
                                            staticStyle: { width: "95%" },
                                            attrs: {
                                              data: _vm.ruleForm
                                                .charteredCostManagementDetails,
                                              border: "",
                                              height: "auto",
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "costName",
                                                label: "费用名称",
                                                align: "center",
                                                width: "140px",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "modelTypeDescription",
                                                label: "费用描述",
                                                align: "center",
                                                width: "300",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "lineHeight26 explain",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .modelTypeDescription
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2933945556
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "cost",
                                                label: "收费标准",
                                                align: "center",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "unit",
                                                label: "单位",
                                                align: "center",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.close } },
                    [_vm._v(_vm._s(_vm.type != "详情" ? "取 消" : "关 闭"))]
                  ),
                  _vm.type != "详情"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.confirm },
                        },
                        [_vm._v("确 认")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }